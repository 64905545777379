<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
          :label="$t('Invoices')"
          text-class="text-h6"
        />

        <search
          dense
          autoset
          is-expandable
          @submit="handleSearch"
        />

        <q-space />

        <q-btn
          :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
          text-color="white"
          size="sm"
          class="q-mr-sm"
          :label="filterBtnText"
          no-caps
          unelevated
          @click="openCloseFilters"
        />

        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Refresh')"
          size="sm"
          no-caps
          unelevated
          @click="refreshItems"
        />

        <q-btn
          color="light-blue-9"
          text-color="white"
          class="q-ml-sm"
          icon="add"
          size="sm"
          no-caps
          unelevated
          @click="create"
        />
      </q-card-section>

      <filter-collapse
        :is-open="isOpen"
        :options="{
          defaultFilter: serverParams.filter,
          fields: activatedFields,
          values: {
            states: statuses
          },
          style: {
            noGroups: true
          }
        }"
        @submit="handleFiltersSubmit"
        @close="openCloseFilters"
      />

      <q-card-section class="q-ma-none q-pa-none">
        <q-table
          style="height: calc(100vh - 130px);"
          class="sticky-header-table"
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="invoices"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="invoicesLoading"
          :filter="filter"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
              showing
              color="primary"
            />
          </template>

          <template v-slot:body="props">
            <invoice-row
              :data="props"
              has-account
              has-payee
              has-payer
              @dblclick="onRowDblClick(props.row)"
            />
          </template>

          <template v-slot:bottom-row>
            <q-tr>
              <q-td class="border-top border-right text-center">
                <strong>
                  {{ $t('Total') }}
                </strong>
              </q-td>

              <q-td class="border-top"/>

              <q-td class="border-top"/>

              <q-td class="border-top"/>

              <q-td class="border-top"/>

              <q-td class="border-top"/>

              <q-td class="border-top">
                <strong>
                  {{ totalPrice }}
                </strong>
              </q-td>

              <q-td class="border-top"/>

              <q-td class="border-top"/>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>

    <invoice-modal ref="invoiceModal" />
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapActions, mapMutations } from 'vuex'

// Mixins
import TableMixin from '../../components/global/TableMixin.vue'

// Components
import FilterCollapse from '../../components/filters/FilterCollapse.vue'
import Search from '../../components/search/Search.vue'
import InvoiceRow from '../../components/invoice/InvoiceRow.vue'
import InvoiceModal from '../../components/modals/InvoiceModal.vue'

// Utils
import { buildQuery } from '../../utils/query-utils'

export default {
  name: 'Invoices',
  components: {
    Search,
    FilterCollapse,
    InvoiceRow,
    InvoiceModal
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      isOpen: false,
      filter: '',
      totalPrice: 0,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Account'),
          name: 'account',
          align: 'left'
        },
        {
          label: this.$t('Payer'),
          name: 'payer',
          align: 'left'
        },
        {
          label: this.$t('Payee'),
          name: 'payee',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Amount'),
          name: 'value',
          align: 'left'
        },
        {
          label: this.$t('History'),
          name: 'history',
          align: 'center'
        },
        {
          label: this.$t('Download'),
          name: 'documents',
          align: 'center'
        }
      ],
      stateColors: {
        pending: 'info',
        conditionally_paid: 'yellow',
        confirmed: 'green',
        paid: 'success',
        deleted: 'danger'
      },
      statuses: [
        { id: 'pending', title: 'Pending' },
        { id: 'conditionally_paid', title: 'Conditionally_paid' },
        { id: 'confirmed', title: 'Confirmed' },
        { id: 'paid', title: 'Paid' }
      ],
      activatedFields: [
        'id',
        'state',
        'created.from',
        'created.to',
        'account',
        'payee',
        'payer'
      ]
    }
  },
  computed: {
    ...mapGetters([
      'isClient',
      'invoices',
      'totalInvoicesNumber',
      'invoicesLoading',
      'appOptions'
    ]),
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
        ? this.$t('Filtered: ' + this.totalInvoicesNumber)
        : this.$t('Filter')
    }
  },
  mounted () {
    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
      'loadInvoices'
    ]),
    ...mapMutations([
      'upsertInvoice'
    ]),
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    handleFiltersSubmit (filter) {
      this.isOpen = false
      return this.onRequest({ pagination: { filter, page: 1 } })
    },
    openCloseFilters () {
      this.isOpen = !this.isOpen
    },
    loadDefaultItems () {
      return this.onRequest({})
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      if (query['order-by']) {
        query.sort = query['order-by'].reduce((acc, order) => {
          if (order.field === 'created') {
            acc.id = order.direction
          } else {
            acc[order.field] = order.direction
          }

          return acc
        }, {})
      }
      if(!query['order-by']){
        query['order-by'] = [
          { type: 'field', field: 'created', direction: 'desc' }
        ]
        query.sort = { id: 'desc' }
      }

      this.updateParams(query)

      return this.loadInvoices(this.serverParams)
        .then(({ items, totalPages, page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }
          this.totalPrice = 0

          items.forEach(e=>{
            this.totalPrice += e.value
            return
          })
          return { items, totalPages, page, totalItems }
        })
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { search, page: 1 } })
    },
    create () {
      this.$refs.invoiceModal.open()
    },
    onRowDblClick () {
    }
  }
}
</script>
